

.cards{
    display: flex;
}

.card-container{
    width: 27%;
    background: white;
    padding: 18px 15px 20px 15px;
    border-radius: 5px;
    margin-left: 2px;
}

.right, .left{
    width: 35%;
}

.left{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.right{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-right: 3%
}

.card-payouts{
    display: flex;
    justify-content: space-between;
}

.card-payouts p, a{
    font-size: 10px;
}

@media only screen and (max-width: 800px){
    .cards{
        display: block;
    }
    .card-container{
        width: 100%;
        /*background: whitesmoke;*/
        margin-bottom: 10px;
    }
    .card-container h2{
        font-size: 15px;
    }
}
