/*Register Form styles*/

.auth-row{
    position: fixed;
}

.register-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    /*margin: auto;*/
    padding-top: 3%;
    align-items: center;
    height: 100vh;
    overflow: scroll;
}
.register-container .a1{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 5%;
    font-size: 15px;
}

.register-container .a1:hover{
    text-decoration: underline;
}

.register-container .inner{
    width: 60%;
    padding-top: 10%;
}

.register-container .label{
    font-weight: bolder;
}

.register-container .input{
    borderRadius: 8px
}

.register-container .button {
    width: 100%;
    borderRadius: 5px;
}

.register-container .p2{
    width: 80%;
    margin: 20px auto;
}

/*Login Form styles*/

.login-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100vh;
}

.login-container .inner{
    width: 60%;
}

.login-container .input{
    border-radius: 8px;
}

.login-container .button{
    width: 100%;
    border-radius: 5px;
}

.login-container .label{
    font-weight: bolder;
}

.forgot-password{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.forgot-password a{
    font-size: 14px;
}


/* Side-B Styles*/

.side-b{
    width:100%;
    padding: 4%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}


/*ACCEPT INVITATION*/

.accept-invite{
    padding-top: 50px;
}

.accept-invite h1{
    padding-left: 40px;
}

.accept-invite .content{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
}


.side-b img{
    width: 100%;
}

.side-b .right{
    width: 100%;
    text-align: right;
}

.side-b a,.side-b h1,.side-b h3{
    color: powderblue;
}


/*Email/phone verification*/

.verify-container{
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 90px 0;
}

.otp-container{
    text-align: center;
    padding: 30px 20px;
    background:#FFEEE2;
}

.otp-container p{
    margin-bottom: 20px;
    color: #E27626;
}

.otp-input-container{
    display: flex;
    justify-content: center;
}

.otp-input{
    /*width: 990px;*/
    /*height: 40px;*/
    /* padding: 5px 10px; */
    margin: 7px;
    font-size: 18px;
    border: 1px solid #000;
    border-radius: 6px;
    text-align: center;
    background: white;
    outline: #E27626;
}


.verify-submit{
    margin-top: 20px;
    width: 200px;
}

.verify-resend{
    font-size: 10px;
    margin-top: 5px;
}

.verify-resend span{
    color:#E27626;
    text-decoration: underline;
    cursor: pointer;
}



@media only screen and (max-width: 360px) {
    .register-container .inner{
        width: 90%;
    }
}
@media only screen and (max-width: 520px) {
    .otp-container{
        width: 90%;
    }
}


@media only screen and (max-width: 991px){
     .login-container{
        height: 90vh;
    }
    .register-container{
        overflow: hidden;
        height: auto;
    }
    .auth-row{
        position: relative;
    }
}




