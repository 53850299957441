


/*Top Nav Second part*/

.top-nav{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.top-nav .menu1{
    display: flex;
    padding-left: 20px;
    /*align-items: center;*/
    border: none;
    width: 100%;
    height: 10vh;
}

.top-nav .menu2{
    /* width: 50%; */
    min-width: fit-content;
    display: flex;
    border: none;
    justify-content: flex-end;
    padding-right: 20px;
    height: 10vh;
}

.user-info{
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    margin-left: 10px;
    font-family: sans-serif;
}


.go-logo{
    width: 10%;
    height: 100%;
    border: none;
    cursor: pointer

}
.go-logo img{
    width: 35px;

}

.side-bar-toggle{
    margin-top: 12px;
    width: auto
}


.nav-display-none{
    display: none;
}

.input{
    border-radius: 8px;
}

/*Side Nav*/
.side-nav{
    overflow: auto;
    height: 90vh;
    position: sticky;
    top: 10vh;
}


.nav-title{
    font-weight: bold;
    font-size: 15px;
}

.top-nav-header{
    padding: 0;
    margin: 0;
    height: 10vh;
    background: white;
    position: sticky;
    z-index: 10;
    top: 0;
}


/*Mobile View*/
.top-nav-mobile{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.top-nav-mobile div{
    display: flex;
    align-items: center;
    height: 100%;

}


@media (max-width: 1000px) {
    .user-info{
        margin-top: 0;
        margin-right: 10px;
    }
}


.top-nav-mobile .user-info{
    align-items: flex-end;
}


.user-info p{
    height: 20px;
    margin: 0;
}

.user-info .p1{
    font-size: 15px;
    margin-bottom: -5px;
}

.user-info .p2{
    font-size: 10px;
    color: #595959;
    font-family: "Dosis", Arial, Helvetica, sans-serif;
}

.top-nav-mobile .mobile-menu{
    color: black;
    font-size: 25px;
    margin-left: 8px;
    cursor: pointer;
}


/*Drawer*/

.drawer-list{
    width: 100%;
    list-style-type: none;
    margin: 0;
    position: absolute;
    right: 30px;
    /*bottom: 0px;*/
}

.drawer-list li{
    /*padding: 5px 0;*/
    width: 100%;
}

.drawer-list .link{
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.drawer-list .link span{
    margin: 0 10px;
}


@media screen and (max-width: 1000px){
    .user-info .p1{
        margin-bottom: -3px;
    }
}




